<template>
  <div>
    <Header :headerIndex="2"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner2.png" alt="" />
      <div class="product_center_wrap">
        <div class="max_1440">
          <div class="product_navigator flex-box flex-wrap">
            <router-link to="" :class="isactive == index ? 'navigator active' : 'navigator' " v-for="(item,index) in typelist" @click.native="listbyid(item.id,index)">
              <div>{{ item.name }}</div>
            </router-link>
            <!-- <router-link to="" class="navigator active" @click.native="listbyid(2)">
              <div>TPU color</div>
              <div>changing car</div>
              <div>clothing</div>
            </router-link>
            <router-link to="" class="navigator" @click.native="listbyid(3)">
              <div>TPU</div>
              <div>invisible</div>
              <div>car suit</div>
            </router-link>  -->
          </div>

          <div class="g-select-wrap">
            <!-- <el-select @click.native="getclass" class="g-select mr30" v-model="classValue" placeholder="Select Product Classification">
              <el-option @click.native="classclear" value="">Select Product Classification</el-option>
              <el-option  v-for="(item,index) in classOptions" :key="item.index" :label="item.name" :value="item.name" @click.native="productlists()" > </el-option>
            </el-select> -->
            <!-- <el-select @click.native="getseries" class="g-select mr30" v-model="seriesValue" placeholder="Select Product Series">
              <el-option @click.native="seriesclear" value="">Select Product Series</el-option>
              <el-option @click.native="productlists()" v-for="(item,index) in seriesOptions" :key="item.index" :label="item.name" :value="item.name"> </el-option>
            </el-select>
            <el-select @click.native="getcolor" class="g-select" v-model="colorValue" placeholder="Choose color model">
              <el-option @click.native="colorclear" value="">Choose color model</el-option>
              <el-option @click.native="productlists()" v-for="(item,index) in colorOptions" :key="item.index" :label="item.name" :value="item.name"> </el-option>
            </el-select> -->
          </div>

          <div class="g_product_list flex-box flex-wrap flex-col-start">
            <router-link :to="{path:'/ProductDetail',query:{productid:item.id}}" class="product_item" v-for="(item, index) in productlist" :key="index" :data-id="item.id">
              <img class="img" :src="item.thumb_image" alt="" />
              <div class="name m-ellipsis-l2">{{item.name }}</div>
            </router-link>
          </div>
          <div :style="{display:display}">
            <Pagination :sumCount="total" :now="page" :perCount="per_page" @pageTurn="pageTurn"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api";
export default {
  components: {},
  data() {
    return {
      classOptions: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      // classValue: "",

      seriesOptions: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      // seriesValue: "",

      colorOptions: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      // colorValue: "",
      // classid:0,
      // seriesid:0,
      // colorid:0,
      page:1,
      typelist:[],    //产品类别
      type_id:-1,
      productlist:[], //产品列表
      total: 1,     //底边页面数
      per_page: 8, //页面含量
      isactive:0,
      display:''
    };
  },
  methods: {
    pageTurn(page) {
      this.page = page + 1
      var page = this.page
        // var limit = this.per_page
        // var type_id=this.type_id
      var json = {
        // class:this.classid?this.classid:0,
        // series:this.seriesid?this.seriesid:0,
        // color:this.colorid?this.colorid:0,
        limit:this.per_page,
        page:this.page,
        type_id:this.type_id
      }
      api.doPost('/api/product/listbyid',json,(res)=>{
          if(res.code == 400){
            this.productlist=[]
          }else{
            // console.log('1111111',res)
            this.productlist=res.data
            this.total = res.count
          }
          if(this.total<=this.per_page){
              this.display = 'none'
          }else{
            this.display = 'block'
          }
      })
    },
    //获取模块类别
    gettype(){
      api.doGet('/api/product/gettype',{},(res=>{
        // console.log(res)
        if(res.code == 200){
          this.typelist = res.data
          this.getproduct(this.typelist[0].id)
        }
      })) 
    },
    //根据type_id获取product
    listbyid(id,index){
      // console.log(id)
      this.type_id = id
      var type_id = id
      var limit = this.per_page
      this.isactive = index         //css 选中
      var page = 1  
      //筛选为空
      // this.classValue = ''
      // this.seriesValue = ''
      // this.colorValue = ''
      //筛选id清空
      // this.colorclear()
      // this.seriesclear() 
      // this.pageTurn(0)
      api.doPost('/api/product/listbyid',{type_id:type_id,limit:limit,page:page},(res)=>{
          if(res.code == 400){
            this.productlist=[]
            this.total = 1
          }else{
            // console.log('page',this.page)
            this.productlist=res.data
            this.total = res.count
            this.page = 1
          }
          if(this.total<=this.per_page){
              this.display = 'none'
          }else{
            this.display = 'block'
          }
      })
    },
    // getclass(){
    //   api.doGet('/api/product/getclass',{},(res)=>{
    //     this.classOptions = res
    //   })
    // },
    // classclear(){
    //     this.classid = 0
    //     this.page = 1
    //     // console.log(this.classValue)
    //     this.productlists()
    // },
    // getseries(){
    //   api.doGet('/api/product/getseries',{},(res)=>{
    //     this.seriesOptions = res
    //   })
    // },
    // seriesclear(){
    //     this.seriesid = 0
    //     this.productlists()
    // },
    // getcolor(){
    //   api.doGet('/api/product/getcolor',{},(res)=>{
    //     this.colorOptions = res
    //   })
    // },
    // colorclear(){
    //     this.colorid = 0
    //     this.productlists()
    // },
    //商品加载
    getproduct(type_id){
      this.type_id = type_id
      var page = this.page
      var limit = this.per_page
      api.doPost('/api/product/getproduct',{page:page,limit:limit,type_id:type_id},(res)=>{
          if(res.code == 400){
            this.productlist=[]
          }else{
            // console.log('擦',res.data)
            this.productlist=res.data
            this.total = res.count
          }
          if(this.total<=this.per_page){
              this.display = 'none'
          }else{
            this.display = 'block'
          }
      })
    },
    //条件搜索
    // productlists(){
    //   // for(var i=0;i<this.classOptions.length;i++){
    //   //   if(this.classOptions[i].name == this.classValue){
    //   //       console.log('classid',this.classOptions[i].id)
    //   //       this.classid = this.classOptions[i].id
    //   //   }
    //   // }
    //   for(var i=0;i<this.seriesOptions.length;i++){
    //     if(this.seriesOptions[i].name == this.seriesValue){
    //         console.log('seriesid',this.seriesOptions[i].id)
    //         this.seriesid = this.seriesOptions[i].id
    //     }
    //   }
    //   for(var i=0;i<this.colorOptions.length;i++){
    //     if(this.colorOptions[i].name == this.colorValue){
    //         console.log('colorid',this.colorOptions[i].id)
    //         this.colorid = this.colorOptions[i].id
    //     }
    //   }
    //   var json = {
    //     // class:this.classid?this.classid:0,
    //     series:this.seriesid?this.seriesid:0,
    //     color:this.colorid?this.colorid:0,
    //     limit:this.per_page,
    //     page:1,
    //     type_id:this.type_id
    //   }
    //   this.page = 1
    //   api.doPost('/api/product/productlist',json,(res)=>{
    //       if(res.code == 400){
    //         this.productlist=[]
    //       }else if(res.code == 201){
    //         // console.log('类别列表',res.data.length)
    //         this.productlist=res.data
    //         this.total = res.count
    //       }else{
    //         // console.log('类别列表',res.data.length)
    //         this.productlist=res.data
    //         this.total = res.data.length
    //       }
    //   })
    // },
  },
  //初加载
  created(){
    //模块菜单
    this.gettype()
  }
};
</script>
